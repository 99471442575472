<template>
    <div>
        <input type="hidden" name="search_property[areaList]" :value="selectedIds">
        <ul class="select-selection" ref="dropdownMenu">
            <li class="search" v-bind:class="{active: active}">
                <ul @click="focusInput">
                    <template v-for="(item,k) in selected" :key="k">
                        <li class="selected" @click="remove(item.id)">{{item.name}}</li>
                    </template>
                    <li class="search-field">
                        <input type="text" @keyup="search" id="multiselect_search" @click="showResults = false" :placeholder="placeholder" autocomplete="off">
                    </li>
                </ul>
            </li>
            <li>
                <ul v-if="showResults" class="selection__rendered">
                    <template v-for="(item,k) in result" :key="k">
                        <li @click="select(item.id)">
                            <span>{{item.name}}</span>
                        </li>
                    </template>
                </ul>
            </li>
        </ul>
    </div>
</template>

<script>
    import axios from "axios";

    export default {
        name: "MultiSelect",
        props: {
            arealist: String,
            placeholder: String,
            searchbyidurl: String,
            searchbynameurl: String
        },
        data() {
            return {
                active: false,
                input: null,
                result: [],
                selected: [],
                selectedIds: [],
                showResults: false
            };
        },
        mounted() {
            const inputa = document.getElementById("multiselect_search");
            if (inputa) {
                this.input = inputa;
            }
            if (this.arealist && this.arealist.length > 0) {
                axios
                    .get(this.searchbyidurl + "?q=" + encodeURIComponent(this.arealist))
                    .then((response) => {
                            if (response.data.length > 0) {
                                this.selected = response.data;
                            }
                        }
                    );

                this.selectedIds = this.arealist.split(",");
            }
        },
        methods: {
            documentClick(e) {
                let el = this.$refs.dropdownMenu;
                let target = e.target;
                if ((el !== target) && !el.contains(target)) {
                    this.showResults = false;
                    this.active = false;
                    this.input.value = "";
                }
            },
            focusInput() {
                this.input.focus();
                this.active = true;
            },
            resultFilter(firstArray, secondArray) {
                return firstArray.filter((firstArrayItem) => !secondArray.some(
                        (secondArrayItem) => firstArrayItem.id === secondArrayItem.id
                    )
                );
            },
            async search(event) {
                event.preventDefault();
                const val = event.target.value;

                if (val.length > 2) {
                    await axios
                        .get(this.searchbynameurl + "?q=" + encodeURIComponent(val))
                        .then((response) => {
                            if (response.data.length > 0) {
                                this.result = this.resultFilter(response.data, this.selected);
                                this.showResults = this.result.length > 0;
                            } else {
                                this.result = [];
                                this.showResults = false;
                            }
                        }
                    );
                }
                else {
                    this.result = [];
                    this.showResults = false;
                }
            },
            select(id) {
                const selected = this.result.filter(el => el.id === id);
                this.selected.push(selected[0]);
                this.check(this.result, selected[0]);
                if (this.result.length === 0) this.showResults = false;
                if (!this.selectedIds.includes(id)) {
                    this.selectedIds.push(id);
                }
                this.input.value = "";
                this.showResults = false;
            },
            remove(id) {
                this.showResults = false;
                const selected = this.selected.filter(el => el.id === id);
                this.check(this.selected, selected[0]);
                this.check(this.selectedIds, id);
                this.input.value = "";
            },
            check(arr, el) {
                if (arr.includes(el)) {
                    let index = arr.indexOf(el);

                    if (index > -1) {
                        arr.splice(index, 1);
                    }
                }
            }
        },
        created() {
            document.addEventListener("click", this.documentClick);
        },
        destroyed() {
            document.removeEventListener("click", this.documentClick);
        }
    };
</script>

<style scoped>
    ul, li{
        list-style: none;
        padding-left: 0;
    }
    .select-selection{
        width: 100%;
        background: #fff;
        position: relative;
    }
    .select-selection .search{
        padding: 7px 10px;
        border: 1px solid #ced4da;
        border-radius: 5px;
        min-height: 50px;
    }
    .select-selection:after{
      content: "";
      background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e") right 8px center no-repeat rgb(255, 255, 255);
      background-size: 12px;
      transition: all 0.1s linear;
      position: absolute;
      width: 30px;
      height: 30px;
      top: 10px;
      right: 10px;
    }
    .select-selection:hover:after{
      transform: rotate(180deg)
    }
    .select-selection .search ul li{
        display: inline-block;
    }
    .select-selection .search ul .selected{
      background: #1aa71c;
      border: 1px solid #3c763d;
      border-radius: 0.25rem;
      color: #fff;
      margin: 2px 4px 2px;
      padding: 0px 5px;
      cursor: pointer;
    }
    .select-selection .search ul .selected:after{
        content: "×";
        margin-left: 7px;
        vertical-align: middle;
        font-size: 20px;
        font-weight: bold;
    }
    .select-selection .search ul li input{
        height: 30px;
        width: 100%;
        vertical-align: middle;
        outline: none;
        border: none;
    }
    .select-selection .active{
      border: 1px solid #159f6a82;
      box-shadow: 0 0 5px -1px #1e7a06;
    }
    .select-selection .selection__rendered{
        border: 1px solid #3c763d;
        max-height: 400px;
        overflow: auto;
    }
    .select-selection .selection__rendered li{
        padding: 7px 10px;
    }
    .select-selection .selection__rendered li:hover{
        background: #1aa71c;
        color:#fff;
        cursor: pointer;
    }
</style>
